// import logo from './logo.svg';
// import './App.css';
import Todo from "./components/Todo";
import Form from "./components/Form";
import FilterButton from "./components/FilterButton";
import React, { useEffect, useState } from "react";
import { nanoid } from "nanoid";

function App() {

  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    fetch('https://jsonplaceholder.typicode.com/posts')
    .then(response => response.json())
    .then(data => {
        // alert(data[0].title);
        // const slicedData = data.slice(0, 5);
        // console.log(data);
        // let counter = 0;
        // let allTsks = [];
        // while (counter < 5){
          // const defTasks = {id: data[counter].id, name: data[counter].title, completed: false};
          // alert(data[counter].title);
          // allTsks.push(defTasks);
          // console.log(data[counter].title);
          // setTasks([...tasks, defTasks]);
          // counter++;
        // }
        // console.log(allTsks);
        // setTasks([...tasks, allTsks]);
        // setTasks([...tasks, data.slice(0,5)]);
        // setTasks(data.slice(0, 5));

        const allTodos = data.map ((task) => {
            var todo = {
              "id": task.id,
              "name": task.title,
              "completed": task.completed
            }
            return todo;
        });
        setTasks(allTodos.slice(0, 5));
    });
  }, [setTasks]);

  function addTask(name) {
    alert(name);
    const newTask = { id: `todo-${nanoid()}`, name, completed: false};
    setTasks([...tasks, newTask]);
  }
  // console.log(tasks);
  function toggleTaskCompleted(id) {
    // console.log(tasks[0]);
    const updatedTasks = tasks.map((task) => {
      // if this task has the same ID as the edited task
      if (id === task.id) {
        // use object spread to make a new object
        // whose `completed` prop has been inverted
        return {...task, completed: !task.completed}
      }
      return task;
    });
    setTasks(updatedTasks);
  }
  
  function deleteTask(id) {
    // console.log(id);
    const remainingTasks = tasks.filter((task) => id !== task.id);
    setTasks(remainingTasks);
  }
  
  const taskList = tasks.map((task) => (
    <Todo name = {task.name} id = {task.id} completed = {task.completed} key = {task.id} toggleTaskCompleted = {toggleTaskCompleted} deleteTask = {deleteTask}/>
  ));

  const tasksNoun = taskList.length !== 1 ? 'tasks' : 'task';
  const headingText = `${taskList.length} ${tasksNoun} remaining`;
  return (
    <div className="todoapp stack-large">
      <h1>TodoMatic</h1>
      <Form addTask = {addTask} />
      {/* <div className="filters btn-group stack-exception">
        <button type="button" className="btn toggle-btn" aria-pressed="true">
          <span className="visually-hidden">Show </span>
          <span>all</span>
          <span className="visually-hidden"> tasks</span>
        </button>
        <button type="button" className="btn toggle-btn" aria-pressed="false">
          <span className="visually-hidden">Show </span>
          <span>Active</span>
          <span className="visually-hidden"> tasks</span>
        </button>
        <button type="button" className="btn toggle-btn" aria-pressed="false">
          <span className="visually-hidden">Show </span>
          <span>Completed</span>
          <span className="visually-hidden"> tasks</span>
        </button>
      </div> */}
      <FilterButton />
      <h2 id="list-heading">
        {/* 3 tasks remaining */}
        {headingText}
      </h2>
      <ul
        role="list"
        className="todo-list stack-large stack-exception"
        aria-labelledby="list-heading"
      >
        
        {/* <Todo name="Eat" completed = {true} id = "todo-1"/>
        <Todo name="Sleep" completed = {false} id = "todo-2"/>
        <Todo name="Repeat" completed = {false} id = "todo-3"/> */}

        {taskList}

      </ul>
    </div>
  );
}

export default App;
